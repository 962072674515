.swipe-nav-bar {
	display: flex;
	align-items: center;
	top: 0px;
	width: 100%;
	overflow: hidden;

	.nav-bar-content {
		z-index: 1;
		width: 100%;
		padding: 0.5em;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	a svg, a:hover svg {
		color: #2E5DFF;
		text-decoration: none;
	}

	a, a:hover {
		color: white;
		text-decoration: none;
	}

	.nav-bar-content {
		padding: 10px;
		justify-content: space-between;

		.square-icon {
			font-size: 1.8em;
		}

		a > svg {
			font-size: 1.8em;
			padding: 0.25em;
		}
	}
}