$background-color: #2e5dff;

.swipe-settings-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	padding: 0 10px;
	flex-grow: 1;

	.swipe-settings {
		max-width: 900px;
		width: 100%;
		max-height: 100%;
		overflow-y: auto;

		.settings-title {
			text-transform: uppercase;
			font-weight: 600;
			font-family: sans-serif;

			display: flex;
			justify-content: space-between;
			align-content: center;

			* {
				padding: 25px 20px;
			}
		}

		.settings-body {
			background-color: $background-color;
			border: 0px solid;
			border-radius: 8px;


			.setting {
				padding: 10px 2em;
				border: 0px solid #18318b;
				border-bottom-width: 1px;
	
				.setting-label {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}

				.input-range {
					width: 100%;
					padding: 1em 0;

					.input-range__track {
						height: 4px;
						transition: left 100ms linear, width 100ms linear;

						.input-range__slider-container {
							transition: left 100ms linear;
						}
					}

					.input-range__track--background {
						background: #18318b33;
					}

					.input-range__track--active {
						background: #FFFFFF;
					}

					.input-range__slider {
						position: relative;
						right: 1em;
						border: none;
						border-radius: 1em;
						height: 2em;
						width: 2em;
						background: #FFFFFF;
						box-shadow: 0 2px 10px 0 rgba(84,84,84,0.8);
						margin-top: -1.15em;
						margin-left: 0;
					}
				}
			}

			.requirement-message {
				height: 2em;
				font-size: 0.8em;
				padding: 0 0.25em;
				transition: opacity 200ms ease-in;
			}

			.setting:first-child {
				border-top-width: 1px;
			}

			.setting:last-child {
				border-bottom-width: 0px;
				padding: 15px 2em;
			}

			a .setting:first-child {
				border-top-width: 0px;
			}

			.fa-chevron-right {
				padding-left: 0.5em;
				font-size: 1em;
				vertical-align: middle;
			}
		}
	}
}