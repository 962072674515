.details {
	padding: 1em;
	box-sizing: border-box;

	h2 {
		display: block;
		text-align: center;
		padding: 1rem;
	}

	.inputs {
		display: grid;
		grid-gap: 1em;
		grid-template-columns: repeat(2, minmax(150px, 1fr));
		padding-bottom: 1em;

		.full-width {
			grid-column-start: 1;
			grid-column-end: end;
		}

		.first-name {
			grid-column-start: 1;
			grid-column-end: 1;
		}

		.last-name {
			grid-column-start: 2;
			grid-column-end: end;
		}

		@media (max-width: 601px) {
			grid-template-columns: repeat(1, minmax(150px, 1fr));

			.first-name, .last-name {
				grid-column-start: 1;
				grid-column-end: end;
			}
		}
	}

	.validate-message {
		transition: opacity 200ms ease-in-out;
		padding: 1em 2em;
	}
}