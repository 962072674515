.left-align {
    .deleted-message {
        justify-content: flex-start;

        .body {
            border-bottom-left-radius: 0.25em;
            background-color: #16308b;
        }
    }
}

.right-align {
    .deleted-message {
        justify-content: flex-end;

        .body {
            border-bottom-right-radius: 0.25em;
            background-color: #2e5dff;
        }
    }
}

.deleted-message {
    flex-grow: 1;
    display: flex;

    .body {
        font-style: italic;
        display: flex;
        padding: 0.5em 0.75em;
        border-radius: 1em;
        border-width: 1px;
    }
    
}