.modal-root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
    z-index: 1001;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity ease-in-out 200ms;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    > * {
        box-shadow: 0px 0px 27px 8px rgba(0,0,0,0.3);
    }
}