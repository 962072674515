.text-input {
    flex-grow: 1;
    border-radius: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.75em;
    box-sizing: border-box;

    > * {
        padding: 0 0.75rem;
    }

    &.light {
        background-color: #1e42c4;
        input {
            background-color: #1e42c4;
        }
    }
    &.dark {
        background-color: #16308b;
        input {
            background-color: #16308b;
        }
    }

    .icon {
        padding-right: 0.75rem;
    }
    
    input {
        flex-grow: 1;
        color: #FFFFFF;
        border: 0px solid;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    input:focus {
        outline: 0;
    }
    
    input::placeholder {
        color: #ffffff;
        opacity: 0.8;
    }
}    