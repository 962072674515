.profile-card {
	width: 375px;
	height: 667px;
	max-width: calc(100% - 22px);
	max-height: calc(100% - 22px);
	min-height: 375px;

	box-shadow: 0 2px 10px 0 rgba(84,84,84,0.2);
	border-radius: 8px;

	overflow: hidden;
	background-color: #ffffff;
	position: absolute;
	will-change: transform;
	transition: transform ease-in-out 200ms;
	z-index: 201;

	.swipe-indicator {
		position: absolute;
		z-index: 100;
		border: 4px solid;
		border-radius: 4px;
		font-size: 1.45em;
		font-weight: 600;
		line-height: 1;
		padding: 0 4px;
		top: 10%;
		opacity: 0;
		will-change: opacity;
	}

	.like {
		color: #01df8a;
		border-color: #01df8a;
		transform: rotate(-20deg);
		left: 10%;
	}

	.nope {
		color: #ff4848;
		border-color: #ff4848;
		transform: rotate(20deg);
		right: 10%;
	}
}

.profile-card.down-on-card {
	transition: none;
}

* > .profile-card:last-child {
	box-shadow: 0 2px 10px 0 rgba(84,84,84,0.77);
}