.left-align {
    .message-reactions-preview {
        left: 2.5em;
    }
}

.right-align {
    .message-reactions-preview {
        right: 0;
    }
}

.reactable-message {
    display: flex;
    position: relative;
    transition: padding 200ms ease-in-out, opacity 200ms ease-in-out;
    max-width: 100%;
    width: max-content;

    &.has-reactions {
        padding-bottom: 1.25em;
    }
    &:not(.has-reactions) .message-reactions-preview {
        opacity: 0;
    }
    
}