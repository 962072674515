.App {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    min-height: 100%;

    .App-header {
        min-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
    }

    #modal-root {
        color: white;
    }

    .App-content {
        height: 100%;
        width: 100vw;
        z-index: 201;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        user-select: none;
    }

    h1 {
        font-size: 5em;
    }

    h2 {
        font-size: 3.5em;
    }

    h3 {
        font-size: 2em;
    }

    p, ol, div, input, button {
        font-size: 1em;
        font-family: 'Open Sans', sans-serif;
    }

    p, ol, div {
        user-select: none;
    }

    a, a:hover {
		color: white;
		text-decoration: none;
	}
}