.conversation {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    
    .conversation-messages-list {
        flex: 1;
    }
}