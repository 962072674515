.message-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    opacity: 1;
    transition: bottom 500ms ease-in-out;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #16308b;
    color: #d5daeb;
    z-index: 101;

    .action {
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;

        div {
            padding-top: 0.5em;
        }
    }

    .action:active {
        background-color: #1e42c4;
    }
}