.conversation-input {
    display: flex;
    position: relative;
    background-color: #16308b66;
    box-sizing: border-box;
    padding: 0.5em;
    max-width: 100%;

    > svg {
        padding: 0.75em;
        height: 1.5em;
        align-self: flex-end;
    }

    .input-wrapper {
        background-color: #16308b;
        flex-grow: 1;
        border-radius: 2em;
        display: flex;
        flex-direction: column;
        padding: 0.75em;
        
        input {
            padding: 0 0.75em;
            flex-grow: 1;
            color: #FFFFFF;
            border: 0px solid;
            box-sizing: border-box;
            background-color: #16308b;
        }

        input:focus {
            outline: 0;
        }
        
        input::placeholder {
            color: #ffffff;
            opacity: 0.8;
        }
    }
}