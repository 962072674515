.conversation-settings {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    .settings-list-wrapper {
        padding: 0 1em;
        overflow-x: hidden;
        min-height: 100%;
        height: max-content;
        display: flex;

        .settings-section {
            padding: 0 0.25em;
            flex-grow: 1;
            padding-top: 0.75em;

            .section-heading {
                padding-top: 10px;
                font-weight: bold;
                font-size: 1.2em;
            }

            > div {
                padding-bottom: 1em;
            }
        }
    }
}