.conversation-message {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    user-select: none;
    transform: rotateX(180deg);
    // Sort order is reversed as the List is flipped and it's Children are flipped back
	// This is so when the list height it increased, it expands upwards instead of downwards

    .date {
        text-align: center;
		transition: height 200ms ease-in-out, opacity 200ms ease-in-out;
		height: 1.5em;
		opacity: 0.8;
		font-size: 0.8em;
        overflow-y: hidden;

		&.hidden {
			height: 0px;
			opacity: 0;
		}
    }

    &.left-align {
        .message-component, .body {
            justify-content: flex-start;
        }
    }
    
    &.right-align {
        .message-component, .body {
            justify-content: flex-end;
        }
    }

    .message-component {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;

        .sender-img {
            height: 2em;
            width: 2em;
            border-radius: 1em;
            object-fit: cover;
            margin-right: 0.5em;
            align-self: flex-end;
        }

        > * {
            max-width: 75%;
            width: max-content;
        }
    }

    &:first-child {
        padding-top: 3em
    }
    &:last-child {
        padding-bottom: 1em;
    }
}