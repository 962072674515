.profile-image {
    position: absolute;
    width: 100%;
    height: 100%;
    user-select: none;

    &.hidden {
        display: none;
    }

    &.top {
        z-index: 11;
        opacity: 1;
    }

    &.adjacent {
        z-index: 10;
        opacity: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
}