.text-message {
    flex-grow: 1;
    display: flex;

    .body {
        display: flex;
        padding: 0.5em 0.75em;
        max-width: max-content;
        border-radius: 1em;
    }
}

.left-align {
    .text-message {
        .body {
            border-bottom-left-radius: 0.25em;
            background-color: #16308b;
        }
    }
}

.right-align {
    .text-message {
        .body {
            border-bottom-right-radius: 0.25em;
            background-color: #2e5dff;
        }
    }
}