.conversation-settings-nav-bar {
	display: flex;
	align-items: center;
	width: 100%;

	.nav-bar-content {
		z-index: 1;
		width: 100%;
		padding: 0.5em;
		display: flex;
		flex-direction: row;
	    align-items: center;
        
        svg {
            font-size: 1.8em;
            padding: 0.25em;
        }

        & > * {
            padding: 0 0.5em
        }
	}

	svg, a:hover svg {
		color: #2E5DFF;
		text-decoration: none;
	}

    .conversation-title {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
		flex-grow: 1;
    }

    .conversation-icon img {
        height: 2.8em;
        width: 2.8em;
        border-radius: 1.4em;
        object-fit: cover;
    }
}