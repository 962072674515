.replyable-message {
    display: flex;
    align-items: center;
	will-change: transform, opacity;
    transition: transform 200ms linear, opacity 200ms linear;
    width: 100%;
    
    &.slow {
        transition: transform 1s linear;
    }
    
    &.left-align {
        .reply-to-icon {
            left: -2em;
        }
    }

    &.right-align {
        .reply-to-icon {
            right: -2em;
        }
    }

    .reply-to-icon {
        position: absolute;
    }

    .reply-to-icon.animate {
        animation-name: pulse;
        animation-duration: 500ms;
    }

    @keyframes pulse {
        0% {transform: scale(100%);}
        50% {transform: scale(150%);}
        100% {transform: scale(100%);}
    }
}