.profile-card-preview {
	height: 100%;

	@keyframes text-fade-in-right {
		from {opacity: 0;transform: translateX(20px);}
		to {opacity: 1;transform: translateX(0px);}
	}

	@keyframes text-fade-in-left {
		from {opacity: 0;transform: translateX(-20px);}
		to {opacity: 1;transform: translateX(0px);}
	}

	.profile-body {
		position: absolute;
		bottom: 0px;
		z-index: 30;
		width: 100%;
		color: #ffffff;
		padding-top: 50px;
		background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.5));
		text-shadow: 0 1px 1px rgba(0,0,0,0.12);
		cursor: pointer;

		.section {
			padding: 10px 16px;

			.profile-header {
				position: absolute;
				bottom: 0px;
			}
		}

		h3 {
			margin: 0;
			font-weight: 700;
			transition: transform 200ms;
		}

		p {
			margin: 5px 0;
			line-height: 1.8;
		}

		.text-fade-in-right {
			animation-name: text-fade-in-right;
			animation-duration: 500ms;
			animation-iteration-count: 1;
		}

		.text-fade-in-left {
			animation-name: text-fade-in-left;
			animation-duration: 500ms;
			animation-iteration-count: 1;
		}

		p.biography {
			white-space: pre-wrap;
		}

		.age {
			font-size: 0.85em;
			font-weight: 400;
			font-family: sans-serif;
		}

		.svg-inline--fa.fa-w-16 {
			width: 1.125em;
		}
	}
}