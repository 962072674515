.left-align {
    .images-message {
        .images-preview, .images-text {
            background-color: #16308b;
        }

        .body .images-text {
            border-bottom-left-radius: 0.25em;
        }
    }
}

.right-align {
    .images-message {
        .images-preview, .images-text {
            background-color: #2e5dff;
        }

        .body .images-text {
            border-bottom-right-radius: 0.25em;
        }
    }
}

.images-message {
    flex-grow: 1;
    display: flex;

    .body {
        display: flex;
        flex-direction: column;

        img, .images-text {
            min-height: 24px;
            max-height: 400px;
            width: auto;
            max-width: 100%;
        }

        @media (max-width: 601px) {
            img, .images-text {
                max-height: 300px;
            }
        }

        .images-text {
            padding: 0.5em 0.75em;
            border-bottom-left-radius: 1em;
            border-bottom-right-radius: 1em;
        }

        .images-preview {
            overflow: hidden;
            border-top-left-radius: 1em;
            border-top-right-radius: 1em;
            cursor: pointer;
        }
    }
}

#conversation-modal-root {
    .image-view {
        max-width: 100%;
        max-height: 100%;
    }
}