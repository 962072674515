.left-align {
    .reply-message {
        .reply-text, .reply-to {
            border-bottom-left-radius: 0.25em;
            background-color: #16308b;
        }
    }
}

.right-align {
    .reply-message {
        align-items: flex-end;
        .reply-text, .reply-to {
            border-bottom-right-radius: 0.25em;
            background-color: #2e5dff;
        }
        .reply-to-text {
            flex-direction: row-reverse;
        }
        .body {
            align-items: flex-end;
        }
    }
}

.reply-message {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .reply-to-text {
        font-size: 0.9em;
        padding-bottom: 0.25em;
        display: flex;
        align-items: center;

        svg {
            padding: 0 0.4em;
        }
    }

    .reply-to {
        filter: brightness(75%);
        padding-bottom: 1em;
        margin-top: -1em;
        position: relative;
        top: 1em;
        z-index: 1;
        border-radius: 1em;

        .body {
            max-width: 100%;
            width: 100%;
        }
    }

    .reply-text {
        padding: 0.5em 0.75em;
        border-radius: 1em;
        z-index: 2;
        max-width: max-content;
    }

    .body {
        display: flex;
        flex-direction: column;
        max-width: max-content;
    }
    
}