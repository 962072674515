.profile-card-detail {
	width: 100%;
	height: 100%;

	box-shadow: 0 2px 10px 0 rgba(84,84,84,0.77);
	border-radius: 8px;

	overflow: hidden;
	overflow-y: scroll;
	
	scrollbar-width: none;
	-ms-overflow-style: none;  /* IE 10+ */
	&::-webkit-scrollbar {
	  width: 0px;
	  background: transparent; /* Chrome/Safari/Webkit */
	}
	background-color: #ffffff;

	.profile-body {
		background-color: #ffffff;
		color: #2c2c2c;
		position: relative;

		.section {
			padding: 10px 16px;
		}

		hr {
			height: 1px;
			margin: -1px 0 0 0;
			border-width: 0;
			background-color: #e8e8e8;
		}

		h3 {
			margin: 0;
			font-weight: 700;
		}

		p {
			margin: 5px 0;
			color: #545454;

			&:first-child {
				margin-top: 10px;
			}

			&:last-child {
				margin-bottom: 10px;
			}

			.svg-inline--fa.fa-w-16 {
				width: 1.125em;
			}
		}

		p.biography {
			white-space: pre-wrap;
		}

		.age {
			font-size: 0.85em;
			font-weight: 400;
			font-family: sans-serif;
		}

		.toggle-view-mode {
			position: absolute;
			top: -30px;
			right: 5%;
			z-index: 20;

			.circle {
				height: 50px;
				width: 50px;
				border-radius: 25px;
				color: #ff2e38;
				background-color: #ffffff;
				font-size: 50px;
				transition: transform 200ms;
				transform: scale(0.92);

				svg {
					display: block;
					transform: scale(1.05);
				}
			}

			.circle:hover {
				transform: scale(1);
				cursor: pointer;
			}
		}
	}
}