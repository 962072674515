.set-nickname {
	padding: 1em;
    border-radius: 1em;
    background-color: #16308b;
    width: 80%;

    .instructions {
        padding: 1em 0.75em;
    }

    .actions {
    	padding: 1em 0;
        display: flex;
        justify-content: flex-end;

        div {
            cursor: pointer;
            padding: 0.75em 1em;
            margin-left: 1em;
        }
    }
}